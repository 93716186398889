<template>
    <b-modal
        id="modal-subir-enlace"
        ref="my-modal"
        :title="tituloModal"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>

                <!-- categoria -->
                <b-col cols="12">
                    <b-form-group
                        label-for="Categoria"
                    >
                        <label >Categoria <span class="text-danger">(*)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="Categoria"
                            rules="required"
                            >
                            <b-row>
                                <b-col cols="10" style="padding-right:0">
                                    <v-select
                                        label="title"
                                        v-model="mediosCategoria_id"
                                        :options="mediosCategoriaOpciones"
                                        :clearable="false"
                                    >
                                        <template #list-header>
                                            <li
                                            v-b-modal.modal-medios-categoria @click="accionCategoria='registrar'"
                                            class="add-new-client-header d-flex align-items-center my-50"
                                            >
                                            <feather-icon
                                                icon="PlusIcon"
                                                size="16"
                                            />
                                            <span class="align-middle ml-25">Nueva Categoria</span>
                                            </li>
                                        </template>
                                    </v-select>
                                </b-col>
                                <b-col cols="2" style="padding-left:0">
                                    <b-button block :disabled="mediosCategoria_id=='' || mediosCategoria_id.value==1" 
                                        v-b-modal.modal-medios-categoria 
                                        @click="abriComponenteCategoria(mediosCategoria_id)" 
                                        variant="warning"
                                    >
                                        <feather-icon icon="Edit2Icon" />
                                    </b-button>
                                </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.mediosCategoria_id" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Titulo -->
                <b-col cols="12">
                    <b-form-group
                        label-for="Titulo"
                    >
                        <label >Titulo <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Titulo"
                        rules="required"
                        >
                        <b-form-input
                            id="titulo"
                            v-model="titulo"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Titulo"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.titulo" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- url -->
                <b-col cols="12">
                    <b-form-group
                        label-for="Url"
                    >
                        <label >Url <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Url"
                        rules="required|url"
                        >
                        <b-form-input
                            id="Url"
                            v-model="url"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Url"
                        />
                        <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.url" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Miniatura -->
                <b-col cols="12">
                    <b-form-group
                        label="Miniatura"
                        label-for="Miniatura"
                    >
                    <b-input-group>
                        <b-form-file
                            @change="onFileChangedMiniatura"
                            id="wildcarddoc"
                            accept="image/*"
                            v-model="miniatura"
                            placeholder="Seleccione una miniatura del audio ..."
                            drop-placeholder="Soltar archivo aqui ..."
                        />
                        <b-input-group-append>
                            <b-button variant="danger" :disabled="miniatura==null||imagen==null" @click="miniatura = null, imagen = null">
                                <feather-icon icon="XIcon" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                        
                        <small v-for="error in errores.miniatura" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                    
                </b-col>
                <b-col cols="12" v-if="imagen">
                    <div class="boxs boxs1 mb-1">
                        <b-img :src="imagen"
                        center
                        thumbnail
                        fluid
                        class="rounded"
                        alt="Card image cap"
                        />
                    </div>
                </b-col>

                <!-- tipo -->
                <b-col cols="12">
                    <b-form-group
                        label="Tipo"
                        label-for="Tipo"
                    >
                        <b-form-select
                        disabled
                        v-model="tipo"
                        :options="tipoOpciones"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
    <ModalCategoria :accion="accionCategoria" :item="itemCategoria" @cerrarComponente="cerrarComponenteCategoria" @actualizarLista="actualizarListaCategoria" ></ModalCategoria>
    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BFormFile, BImg, BInputGroup, BInputGroupPrepend, BInputGroupAppend } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import vSelect from 'vue-select'
import ModalCategoria from '@/views/aplicaciones/medios/ModalCategoria.vue';

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormFile,
        BImg,
        BInputGroup,BInputGroupPrepend,BInputGroupAppend,
        vSelect,
        BRow,
        BCol,
        BFormSelect,
        ModalCategoria
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        titulo: function () { 
            this.errores.titulo=[];
        },
        url: function () { 
            this.errores.url=[];
        },
        miniatura: function () { 
            this.errores.miniatura=[];
        },
    },
    data () {
        return {
            id:null,
            titulo:'',
            tipo:'AUDIO',
            url:'',
            enlace:1,
            tipoOpciones:[
               { value: 'IMAGEN', text: 'IMAGEN' },
               { value: 'DOCUMENTO', text: 'DOCUMENTO' },
               { value: 'AUDIO', text: 'AUDIO' },
            ],
            tituloModal:'',
            required,
            submitted: false,
            errores:[],
            mediosCategoria_id:{value: 1},
            mediosCategoriaOpciones: [],
            itemCategoria:[],
            accionCategoria:'',
            imagen:'',
            miniatura:'',
        }
    },
    methods:{
        onFileChangedMiniatura (event) {
            if (event.target.files[0]) {
                let file = event.target.files[0];
                if(file==null){
                    this.miniatura='';
                }else{
                    if (file.type.includes('image')) {
                        this.imagen = URL.createObjectURL(file);
                    }else{
                        this.imagen='';
                    }
                }
            }else{
                this.imagen='';
                this.miniatura='';
            }
        },
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        this.registrar();
                    }
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.tituloModal='Subir Enlace  de Audio';
                    this.titulo='';
                    this.url='';
                    this.imagen='';
                    this.miniatura=null;
                    this.tipo='AUDIO';
                    this.enlace=1;
                    this.mediosCategoria_id={title:'General',value: 1};
                    this.listarMediosCategoria();
                    break;
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){            
            let formData = new FormData();
            formData.append('titulo', this.titulo);
            formData.append('url', this.url);
            formData.append('mediosCategoria_id', this.mediosCategoria_id.value);
            formData.append('miniatura', this.miniatura);
            formData.append('tipo', this.tipo);
            formData.append('enlace', this.enlace);
            axiosIns.post('/admin/medios/subir-enlace',
            formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },

        //categoria
        listarMediosCategoria(){
            var url= '/admin/medios-categorias';
            axiosIns.get(url)
            .then(res => {
                this.mediosCategoriaOpciones=res.data.map(g => ({title:g.nombre, value: g.id}));
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarListaCategoria(item){
            this.listarMediosCategoria();
            this.mediosCategoria_id={title:item.nombre,value:item.id};
            this.accionCategoria='';
            this.itemCategoria=[];
        },
        abriComponenteCategoria(item){
            this.accionCategoria="actualizar";
            this.itemCategoria=item;
        },
        cerrarComponenteCategoria(){
            this.accionCategoria='';
            this.itemCategoria=[];
        },
    }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
