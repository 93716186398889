<template>
  <div>
    <b-tabs>
      <b-tab lazy title="Recientes">
        <Recientes />
      </b-tab>
      <b-tab lazy title="Todas">
        <Listar />
      </b-tab>
      <b-tab lazy title="Busqueda">
        <Busqueda />
      </b-tab>
    </b-tabs>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="agregar"
        >
          Añadir al Menu
        </b-button>
      </b-col>
      
    </b-row>
  </div>
</template>
<script>
import axios from "@axios";

import { BTabs, BTab, BButton, BRow, BCol } from "bootstrap-vue";
import Recientes from "@/views/apariencia/menus/eventos/tabs/Recientes.vue";
import Listar from "@/views/apariencia/menus/eventos/tabs/Listar.vue";
import Busqueda from "@/views/apariencia/menus/eventos/tabs/Busqueda.vue";

import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,

    Recientes,
    Listar,
    Busqueda,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    elementsEventos(){
      return this.$store.state.aparienciaMenus.eventos_elementos;
    },

  },
  created() {},
  methods: {
    agregar() {
      this.elementsEventos.forEach((item)=>{
        const d = new Date();
        let time = d.getTime();
        var idtime=item.entrada_id+"-"+time;
        var itemadd = {
        id: item.entrada_id,
        menuitemable_id: item.entrada_id,
        time: idtime,
        tipo: "evento",
        etiqueta: item.entrada.titulo,
        titulo: item.entrada.titulo,
        icono:"",
        
        menu_item_hijas: [],
      };
      this.$store.dispatch("aparienciaMenus/addElements", itemadd);
      });
      this.$store.dispatch("aparienciaMenus/updateElementsEventos", []);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>