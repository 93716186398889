<template>
  <div>
    <b-row>
      <b-col lg="6" class="mb-1">
        <b-button-group v-if="$hascan('medios')">
            <b-button
              v-b-modal.modal-subir-archivo
              @click="accionArchivo = 'registrar'"
              size="sm"
              v-b-tooltip.hover.bottom="'Subir'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
            >
              <feather-icon icon="UploadIcon" class="mr-50" size="17" />
            </b-button>
            <b-button
              v-b-modal.modal-subir-enlace
              @click="accionEnlace = 'registrar'"
              size="sm"
              v-b-tooltip.hover.bottom="'Insertar Url'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
            >
              <feather-icon icon="UploadCloudIcon" class="mr-50" size="17" />
            </b-button>
            <b-button
              @click="actualizarLista"
              size="sm"
              v-b-tooltip.hover.bottom="'Recargar'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
            >
              <feather-icon icon="RefreshCcwIcon" class="mr-50" size="17" />
            </b-button>
            <b-button
              :disabled="selected == ''"
              v-b-modal.modal-view-archivo
              size="sm"
              v-b-tooltip.hover.bottom="'Ver'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
            >
              <feather-icon icon="EyeIcon" class="mr-50" size="17" />
            </b-button>
        </b-button-group>
      </b-col>
      <b-col lg="3" md="6" cols="6">
        <b-form-group
            label=""
            label-for=""
        >
          <v-select
            label="title"
            placeholder="Filtrar por categoria"
            v-model="filtroCategoria"
            :options="filtroOpcionesCategoria"
          />
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6" cols="6">
        <b-form-group
            label=""
            label-for=""
        >
          <b-form-input
            id="filterInput"
            v-model="buscar"
            type="search"
            @keyup.enter="buscaritem()"
            placeholder="Buscar..."
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr style="margin-top: 0rem;"/>
    <b-overlay :show="loading">
      <vue-perfect-scrollbar
        @ps-y-reach-end="onScroll"
        ref="scrollbar"
        :settings="perfectScrollbarSettings"
        class="scroll-area1"
      >
        <b-row class="match-height">
          <b-col
            v-for="(index, file) in items"
            :key="file"
            sm="6"
            md="4"
            lg="2"
            cols="6"
            id="card-media"
          >
            <b-card
              class="cursor-pointer"
              v-model="selected"
              no-body
              border-variant="secondary"
              :bg-variant="selected == index ? 'primary' : ''"
              :text-variant="selected == index ? 'white' : ''"
              @click="seleccionarArchivo(index)"
            >
              <div class="box box1">
                <b-img-lazy
                  v-if="index.miniatura"
                  thumbnail
                  fluid
                  center
                  class="rounded w-100"
                  :src="index.miniatura"
                  :alt="index.titulo"
                  @error.native="replaceByDefault"
                />
                <b-img-lazy
                  v-else
                  thumbnail
                  fluid
                  center
                  class="rounded w-100"
                  :src="require(`@/assets/images/icons/file-icon/${index.extencion}.png`)"
                  :alt="index.titulo"
                />
              </div>
              <small class="px-1 text-truncate text-center">{{ index.titulo }}</small>
            </b-card>
          </b-col>
        </b-row>
      </vue-perfect-scrollbar>
    </b-overlay>
    <ModalSubirEnlace
      :accion="accionEnlace"
      @cerrarComponente="cerrarComponente"
      @actualizarLista="actualizarLista"
    ></ModalSubirEnlace>
    <ModalSubir
      :accion="accionArchivo"
      @cerrarComponente="cerrarComponente"
      @actualizarLista="actualizarLista"
    ></ModalSubir>
    <ModalVerArchivo :selected="selected" :mediosCategoriaOpciones="filtroOpcionesCategoria"></ModalVerArchivo>
  </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BImg,
  BLink,
  BFormCheckbox,
  BCardBody,
  BImgLazy,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay,
  BModal,
  VBModal,
  BButtonGroup,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import ModalSubirEnlace from "@/views/aplicaciones/medios/videos/ModalSubirEnlace.vue";
import ModalSubir from "@/views/aplicaciones/medios/videos/ModalSubir.vue";
import ModalVerArchivo from "@/views/aplicaciones/medios/ModalVerArchivo.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import imageDefault from "@/assets/images/icons/file-icon/unknown.png"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    BImg,
    BImgLazy,
    BLink,
    BFormCheckbox,
    BCardBody,
    ModalSubirEnlace,
    ModalSubir,
    ModalVerArchivo,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    BOverlay,
    BModal,
    BButtonGroup,

    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,
  },
  props:[
      'tipo'
  ],
  data() {
    return {
      selected: "",
      items: [],
      accionEnlace: "",
      accionArchivo: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      buscar: "",
      filtroFecha: "",
      filtroOpcionesFecha: [],
      filtroTipo: { id: "TODOS", title: "Todos" },
      filtroOpcionesTipo: [
        { id: "TODOS", title: "Todos" },
        { id: "IMAGEN", title: "Imagen" },
        { id: "DOCUMENTO", title: "Documento" },
      ],
      filtroOpcionesCategoria: [],
      filtroCategoria: "",
      loading: false,
      page: 0,
      state: true,
      per_page:13,
    };
  },
  created() {
    this.loading = true;
    this.listarMediosCategoria();
    this.listar();
    this.$emit('selectItem',this.selected);
  },
  watch: {
    buscar: function(val) {
      if (val == "" || val.length == 0) {
        this.loading = true;
        this.page = 0;
        this.listarFiltro();
        this.$refs.scrollbar.$el.scrollTop = 0;
      }
    },
    filtroCategoria: function() {
      this.$refs.scrollbar.$el.scrollTop = 0;
      this.state = true;
      this.page = 0;
      this.loading = true;
      this.listarMediosCategoria();
      this.listarFiltro();
    },
    selected: function() {
      this.$emit('selectItem',this.selected);
    },
  },
  methods: {
    buscaritem() {
      this.$refs.scrollbar.$el.scrollTop = 0;
      this.state = true;
      this.page = 0;
      this.loading = true;
      this.listarMediosCategoria();
      this.listarFiltro();
    },
    async listarFiltro() {
      this.page++;
      let filtrocategoria = "";
      if (
        this.filtroCategoria == null ||
        this.filtroCategoria == "" ||
        this.filtroCategoria == "undefined"
      ) {
        filtrocategoria = "";
      } else {
        filtrocategoria = this.filtroCategoria.id;
      }
      await axiosIns
        .get(
          "/admin/medios?page=" +this.page +"&buscar=" + this.buscar + "&filtroCategoria=" + filtrocategoria + "&tipo=" + this.tipo
        )
        .then((res) => {
          this.selected = "";
          this.loading = false;
          this.items = res.data.data;
          this.state = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async listar() {
      this.page++;
      let filtrocategoria = "";
      if (
        this.filtroCategoria == null ||
        this.filtroCategoria == "" ||
        this.filtroCategoria == "undefined"
      ) {
        filtrocategoria = "";
      } else {
        filtrocategoria = this.filtroCategoria.id;
      }
      await axiosIns
        .get(
          "/admin/medios?page=" + this.page + "&buscar=" + this.buscar + "&filtroCategoria=" + filtrocategoria + "&tipo=" + this.tipo
        )
        .then((res) => {
          //this.selected = "";
          this.loading = false;
          let posts = res.data.data;
          if (posts.length) {
            this.items = this.items.concat(posts);
            this.state = false;
          } else {
            this.state = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onScroll(event) {
      if (!this.state && this.items.length>=this.per_page) {
        this.listar();
      }
    },
    actualizarLista() {
      this.$refs.scrollbar.$el.scrollTop = 0;
      this.state = true;
      this.page = 0;
      this.loading = true;
      this.listarFiltro();
      this.listarMediosCategoria();
      this.accionEnlace = "";
      this.accionArchivo = "";
    },
    cerrarComponente() {
      this.accionEnlace = "";
      this.accionArchivo = "";
    },
    seleccionarArchivo(index) {
      if (this.selected) {
        if (this.selected == index) {
          this.selected = "";
        } else {
          this.selected = index;
        }
      } else {
        this.selected = index;
      }
    },
    listarMediosCategoria() {
      var url = "/admin/medios-categorias";
      axiosIns
        .get(url)
        .then((res) => {
          this.filtroOpcionesCategoria = res.data.map((g) => ({
            title: g.nombre,
            id: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    replaceByDefault(e) {      
      e.target.src = imageDefault;      
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.scroll-area1 {
  position: relative;
  margin: auto;
  width: 100%;
  padding: 0 1rem;
  height: calc(var(--vh, 1vh) * 100 - 20rem);
}
.card-size {
  width: 100%;
  height: 12rem;
}
.box {
  height: 12rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: contain;
}
@media (max-width: 700px) {
  .box {
    height: 10rem !important;
  }
}
.vs--single {
  &:not(.vs--open) .vs__selected + .vs__search {
    // force this to not use any space
    // we still need it to be rendered for the focus
    width: 0;
    padding: 0;
    margin: 0;
    border: none;
    height: 0;
  }

  .vs__selected-options {
    // do not allow growing
    width: 0;
  }

  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
