import { render, staticRenderFns } from "./Biblioteca.vue?vue&type=template&id=7d9c90b2&"
import script from "./Biblioteca.vue?vue&type=script&lang=js&"
export * from "./Biblioteca.vue?vue&type=script&lang=js&"
import style0 from "./Biblioteca.vue?vue&type=style&index=0&id=7d9c90b2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports