<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :list="list"
    :value="value"
    @input="emitter"
    group="a"
  >
    <div class="item-group" :key="subIndex" v-for="(el, subIndex) in realValue">
      <li class="list-group-item list-group-item-mine">
        <b>{{ el.etiqueta }}</b>
        <b-button
          v-b-toggle="'accordion-' + el.time"
          variant="info"
          class="mb-1 badge float-right"
        >
          <feather-icon icon="ChevronDownIcon" />
        </b-button>
      </li>

      <b-collapse :id="'accordion-' + el.time" class="mt-1">
        <div>
          <b-card border-variant="primary">
            <div class="div-icon">
              <i class="icon">
                <div v-html="el.icono" class=""></div>
              </i>
            </div>

            <b-form-group
              label="Etiqueta de Navegacion"
              label-for="etiqueta-menu"
            >
              <b-form-input id="etiqueta-menu" v-model="el.etiqueta" />
            </b-form-group>
            <b-form-group label="Icono Menu" label-for="icon-menu">
              <b-form-textarea
                id="icon-menu"
                placeholder="Introduzca el svg del icono"
                v-model="el.icono"
              ></b-form-textarea>
              <!-- <b-form-input id="icon-menu" v-model="el.icono" placeholder="Intruduzca el svg del Icono"/> -->
            </b-form-group>
            <small><b>Nombre Original: </b>{{ el.titulo }}</small>
            <b-row>
              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-danger"
              @click="removeAt(subIndex,el)"
            >
              Eliminar
            </b-button>
            </b-row>
          </b-card>
          <menu-dragable
            class="item-sub"
            :list="el.menu_item_hijas"
            v-if="el.menu_item_hijas"
          />
        </div>
      </b-collapse>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import {
  BInputGroup,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
  BListGroupItem,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  name: "menu-dragable",
  components: {
    draggable,
    BInputGroup,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BCardHeader,
    BFormTextarea,
    BImg,
    BFormFile,
    BFormDatepicker,
    BTabs,
    BTab,

    BListGroupItem,
    BCollapse,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    removeAt(idx,el) { 
      this.$store.dispatch("aparienciaMenus/addElementsEliminados", el);
      if(this.value)
        this.value.splice(idx, 1);
      else
        this.list.splice(idx,1);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
};
</script>
<style scoped>
.item-container {
  max-width: 100%;
  margin: 0;
}
.item {
  padding: 0%;
  border: solid black 1px;
  background-color: #fefefe;
}
.item-sub {
  margin: 0 0 0 5%;
}
.list-group-item-mine {
  background-color: #f1f9fb;
  border-top: 1px solid #4c16df;
  border-left-color: #fff;
  border-right-color: #fff;
}
.div-icon {
  background-color: #0b0e0f;
}
</style>