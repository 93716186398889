<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- nombre -->
          <b-col cols="12">
            <b-form-group label="Nombre" label-for="Nombre">
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required|min:4"
              >
                <b-form-input
                  id="nombre"
                  v-model="enlace.nombre"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nombre Enlace"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- url -->

          <!-- svg enlace -->
          <b-col cols="12">
            <b-form-group label="Icono" label-for="icono">
              <validation-provider #default="{ errors }" name="Icono" rules="">
                <b-form-input
                  id="icono"
                  v-model="enlace.icono"
                  :state="errors.length > 0 ? false : null"
                  placeholder="SVG icono"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.icono"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Url" label-for="url">
              <validation-provider #default="{ errors }" name="Icono" rules="">
                <b-form-input
                  id="url"
                  v-model="enlace.url"
                  :state="errors.length > 0 ? false : null"
                  placeholder="url"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="validar"
            >
              +
            </b-button>
          </b-col>

          <!-- imagen url -->

          <!-- Estado -->
        </b-row>
      </b-form>
    </validation-observer>
    <b-tabs>
      <b-tab lazy title="Recientes">
        <Recientes ref="recientesmenu" />
      </b-tab>
      <b-tab lazy title="Todas">
        <Listar />
      </b-tab>
      <b-tab lazy title="Busqueda">
        <Busqueda />
      </b-tab>
    </b-tabs>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="agregar"
        >
          Añadir al Menu
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "@axios";

import {
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BButton,
  BForm,
  BRow,
  BCol,
} from "bootstrap-vue";
import Recientes from "@/views/apariencia/menus/enlacemenus/tabs/Recientes.vue";
import Listar from "@/views/apariencia/menus/enlacemenus/tabs/Listar.vue";
import Busqueda from "@/views/apariencia/menus/enlacemenus/tabs/Busqueda.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,

    Recientes,
    Listar,
    Busqueda,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      enlace: {
        nombre: "",
        url: "",
        icono: "",
      },
      errores: [],
    };
  },
  computed: {
    elementsEntradas() {
      return this.$store.state.aparienciaMenus.enlacemenus_elementos;
    },
  },
  created() {},
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    guardar() {
      axios
        .post("/enlaces/menu", this.enlace)
        .then((res) => {
          this.errores = [];
          this.enlace.nombre="";
          this.enlace.url="";
          this.enlace.icono="";

          this.$refs.recientesmenu.getEnlaceMenusRecientes();
        })
        .catch((err) => {
          console.log(err);

          this.errores = err.response.data.errors;
        });
    },

    agregar() {
      this.elementsEntradas.forEach((item) => {
        const d = new Date();
        let time = d.getTime();
        var idtime = item.id + "-" + time;

        var itemadd = {
          id: item.id,
          menuitemable_id: item.id,
          time: idtime,
          tipo: "enlace",
          etiqueta: item.nombre,
          titulo: item.nombre,
          icono: "",
          menu_item_hijas: [],
        };
        this.$store.dispatch("aparienciaMenus/addElements", itemadd);
      });
      this.$store.dispatch("aparienciaMenus/updateElementsEnlaceMenus", []);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>