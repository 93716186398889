<template>
  <b-card no-body>
    <!--<b-card-text>{{selected}}</b-card-text>-->
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <b-form-group label="Todas la Paginas">
            <b-form-checkbox
              v-for="pagina in items"
              :key="pagina.id"
              v-model="elements"
              :value="pagina"
              name="flavour-3a"
            >
              {{ pagina.titulo }}
            </b-form-checkbox>
          </b-form-group>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from "@axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],

      selected: [],
      filter: null,
      filterOn: [],

      loading: false,
      item: [],
    };
  },
  created() {
    this.loading = true;
    this.$store.dispatch("aparienciaMenus/updateElementsNoticias", []);

    this.getPaginasAll();
  },
  computed: {
    elements: {
      get() {
        return this.$store.state.aparienciaMenus.noticias_elementos;
      },
      set(value) {
        this.$store.dispatch("aparienciaMenus/updateElementsNoticias", value);
      }
    }
  },
  methods: {
    getPaginasAll() {
      axios
        .get(`/menus/listar/noticias`)
        .then((res) => {
          this.items = res.data.data;

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
