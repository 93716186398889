<template>
  <b-row>
    <b-col md="4" lg="4">
      <b-card>
        <b-card-body>
          <app-collapse accordion>
            <app-collapse-item title="Entradas">
              <Entradas></Entradas>
            </app-collapse-item>
            <app-collapse-item title="Noticias">
              <Noticias></Noticias>
            </app-collapse-item>
            <app-collapse-item title="Convocatorias">
              <Convocatorias></Convocatorias>
            </app-collapse-item>
            <app-collapse-item title="Paginas">
              <Paginas></Paginas>
            </app-collapse-item>
            <app-collapse-item title="Eventos">
              <Eventos></Eventos>
            </app-collapse-item>
            <!-- <app-collapse-item title="Medios">
              <Paginas></Paginas>
            </app-collapse-item> -->
            <!-- <app-collapse-item title="Biblioteca">
              <Biblioteca></Biblioteca>
            </app-collapse-item> -->  

            <!-- <app-collapse-item title="Fiscalias">
              <Fiscalias></Fiscalias>
            </app-collapse-item> -->

            <app-collapse-item title="Categoria">
              <Categorias></Categorias>
            </app-collapse-item>
            <app-collapse-item title="Tipo Entradas">
              <TipoEntradas></TipoEntradas>
            </app-collapse-item>
            <app-collapse-item title="Modulos">
              <Modulos></Modulos>
            </app-collapse-item>
            <app-collapse-item title="Item Menu">
              <EnlaceMenu></EnlaceMenu>
            </app-collapse-item>
<!-- 
            <app-collapse-item title="Enlaces Personalizados">
              <b-form-group label="Url" label-for="url" class="mb-0">
                <b-input-group>
                  <b-form-input
                    id="url"
                    v-model="url"
                    placeholder="Url Https://.."
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Nombre Enlace"
                label-for="nombre-enlace"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input id="nombre-enlace" v-model="nombre_enlace" />
                </b-input-group>
              </b-form-group>
            </app-collapse-item> -->
          </app-collapse>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6" lg="6">
      <b-card no-body>
        <b-card-body>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- nombre -->
                <b-col cols="12">
                  <b-form-group label-for="nombre-menu">
                     <label for="nombre-menu"
                        >Nombre del Menu <span class="text-danger">(*)</span></label
                      >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre Menu"
                      rules="required|min:4"
                    >
                      <b-form-input
                        id="nombre-menu"
                        placeholder="Introduzca Nombre del Menu"
                        :state="errors.length > 0 ? false : null"
                        v-model="menu.nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-for="error in errores.nombre"
                        :key="error"
                        class="text-danger"
                        >{{ error }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Estado" label-for="Estado">
                    <v-select
                      id="blog-edit-estado"
                      v-model="estado"
                      label="title"
                      :options="estadoOpciones"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <!-- estado -->
                <b-col md="12">
                  <h6 class="text-primary font-weight-bold mb-2">
                    Items del Menu
                  </h6>

                  <!-- draggable -->
                  <menu-dragable v-model="elements" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Ubicaciones ">
                    <b-form-checkbox
                      v-for="ubi in ubicaciones"
                      :key="ubi.id"
                      v-model="menu.ubicacion"
                      :value="ubi.value"
                      name="flavour-3a"
                    >
                      {{ ubi.titulo }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mt-50">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="validar"
                  >
                    Guardar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    :to="{ name: 'menus' }"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="2" lg="2">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <h6 class="text-primary font-weight-bold mb-2">
                Items Eliminados
              </h6>

              <!-- draggable -->

              <draggable :list="elementsEliminados" group="a">
                <div
                  class="bg-secondary text-light list-group-item item mb-1"
                  v-for="element in elementsEliminados"
                  :key="element.id"
                >
                  {{ element.etiqueta }}
                </div>
              </draggable>
            </b-col>
            <b-row>
              <b-col cols="12" class="mt-50">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-1"
                  @click="eliminar"
                >
                  Vaciar
                </b-button>
              </b-col>
            </b-row>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "@axios";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import Paginas from "@/views/apariencia/menus/paginas/Paginas.vue";
import Entradas from "@/views/apariencia/menus/entradas/Entradas.vue";
import Fiscalias from "@/views/apariencia/menus/fiscalias/Fiscalias.vue";
import Categorias from "@/views/apariencia/menus/categorias/Categorias.vue";
import Convocatorias from "@/views/apariencia/menus/convocatorias/Convocatorias.vue";
import Noticias from "@/views/apariencia/menus/noticias/Noticias.vue";
import TipoEntradas from "@/views/apariencia/menus/tipoentradas/TipoEntradas.vue";
import Eventos from "@/views/apariencia/menus/eventos/Eventos.vue";
import Biblioteca from "@/views/apariencia/menus/bibliotecas/Biblioteca.vue";
import Modulos from "@/views/apariencia/menus/modulos/Modulos.vue";
import EnlaceMenu from "@/views/apariencia/menus/enlacemenus/EnlaceMenu.vue";

import MenuDragable from "@/views/apariencia/menus/menudraggable/menu-dragable.vue";
import { required } from "@validations";

import {
  BInputGroup,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
  BListGroupItem,
  BFormCheckbox,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";
import router from "@/router";
import CategoriaNuevoEditar from "@/views/aplicaciones/configuracion/categoria/CategoriaNuevoEditar.vue";
import EtiquetaNuevoEditar from "@/views/aplicaciones/configuracion/etiqueta/EtiquetaNuevoEditar.vue";
import MediosComponent from "@/componentes/MediosComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";


export default {
  components: {
    BInputGroup,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg,
    BFormFile,
    BFormDatepicker,
    BFormCheckbox,

    CategoriaNuevoEditar,
    EtiquetaNuevoEditar,
    MediosComponent,
    ImagenComponent,
    
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    EnlaceMenu,

    Paginas,
    Entradas,
    Fiscalias,
    Categorias,
    Noticias,
    TipoEntradas,
    Biblioteca,
    Convocatorias,
    Eventos,
    Modulos,
    BListGroupItem,
    Prism,
    draggable,
    MenuDragable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: "",
      list2: [],
      errores: [],
      estadoOpciones: [
        { id: 0, title: "Inactivo" },
        { id: 1, title: "Activo" },
      ],
      nombre_enlace: "",
      id: null,
      menu: {
        ubicacion: [],
        nombre: "",
        tipo: "menu",
      },
      estado: { id: 0, title: "Inactivo" },
      ubicaciones: [
        {
          id: 1,
          titulo: "Menu Principal",
          value: "menu_principal",
        },
        {
          id: 2,
          titulo: "Menu Secundario",
          value: "menu_secundario",
        },
        {
          id: 3,
          titulo: "Menu Superior",
          value: "menu_superior",
        },
      ],
    };
  },
  computed: {
    elements: {
      get() {
        return this.$store.state.aparienciaMenus.elements;
      },
      set(value) {
        this.$store.dispatch("aparienciaMenus/updateElements", value);
      },
    },
    elementsEliminados:{
      get() {
        return this.$store.state.aparienciaMenus.elements_eliminados;
      },
      set(value) {
        this.$store.dispatch("aparienciaMenus/updateElementsEliminados", value);
      },
      
    }
  },
  created() {
    this.elements = [];
    this.elementsEliminados=[];
    if (router.currentRoute.params.id) {
      this.getMenu(router.currentRoute.params.id);
      //this.agregarElements(this.menu.menuitems);
    }
  },
  methods: {
    getMenu(id) {
      axios
        .get(`/menus/${id}`)
        .then((response) => {
          this.menu = response.data.data;
          this.menu.eliminar = [];
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === response.data.data.estado
          );
          this.estado = { id: valor.id, title: valor.title };
          this.menu.ubicacion = [];
          if (this.menu.isprincipal === 1)
            this.menu.ubicacion.push(this.ubicaciones[0].value);
          if (this.menu.issecundario === 1)
            this.menu.ubicacion.push(this.ubicaciones[1].value);
          if (this.menu.issuperior === 1)
            this.menu.ubicacion.push(this.ubicaciones[2].value);
          this.agregarElements(this.menu.menuitems);
        })
        .catch((error) => console.error(error));
    },
    agregarElements(arrayElementos) {
      arrayElementos.forEach((element) => {
        //const d = new Date();
        //let time = d.getTime();
        var idtime = element.idtime;
        var tipoe = "";

        if (element.menuitemable_type == "App\\Models\\Categoria")
          tipoe = "categoria";
        if (element.menuitemable_type == "App\\Models\\Fiscalia")
          tipoe = "fiscalia";
        if (element.menuitemable_type == "App\\Models\\Convocatoria")
          tipoe = "convocatoria";
        if (element.menuitemable_type == "App\\Models\\Entrada")
          tipoe = "entrada";
        if (element.menuitemable_type == "App\\Models\\Evento")
          tipoe = "evento";
        if (element.menuitemable_type == "App\\Models\\Libro") tipoe = "libro";
        if (element.menuitemable_type == "App\\Models\\TipoEntrada")
          tipoe = "tipoentrada";
        if (element.menuitemable_type == "App\\Models\\Modulo")
          tipoe = "modulo";
        if (element.menuitemable_type == "App\\Models\\Enlace")
          tipoe = "enlace";
        var itemadd = {
          id: element.id,
          time: idtime,
          tipo: tipoe,
          etiqueta: element.nombre,
          titulo: element.titulo,
          icono: element.icono,
          menuitemable_id: element.menuitemable_id,
          menu_item_hijas: [],
        };
        this.anadir_items_hijas(itemadd, element.menu_item_hijas);
        this.$store.dispatch("aparienciaMenus/addElements", itemadd);
      });
    },
    anadir_items_hijas(item, hijos) {
      hijos.forEach((element) => {
        //const d = new Date();
        //let time = d.getTime();
        var idtime = element.idtime;
        var tipoe = "";
        if (element.menuitemable_type == "App\\Models\\Categoria")
          tipoe = "categoria";
        if (element.menuitemable_type == "App\\Models\\Fiscalia")
          tipoe = "fiscalia";
        if (element.menuitemable_type == "App\\Models\\Convocatoria")
          tipoe = "convocatoria";
        if (element.menuitemable_type == "App\\Models\\Entrada")
          tipoe = "entrada";
        if (element.menuitemable_type == "App\\Models\\Evento")
          tipoe = "evento";
        if (element.menuitemable_type == "App\\Models\\Libro") tipoe = "libro";
        if (element.menuitemable_type == "App\\Models\\TipoEntrada")
          tipoe = "tipoentrada";
        if (element.menuitemable_type == "App\\Models\\Modulo")
          tipoe = "modulo";
        if (element.menuitemable_type == "App\\Models\\Enlace")
          tipoe = "enlace";
        var itemadd = {
          id: element.id,
          time: idtime,
          tipo: tipoe,
          etiqueta: element.nombre,
          titulo: element.titulo,
          icono: element.icono,
          menuitemable_id: element.menuitemable_id,
          menu_item_hijas: [],
        };
        item.menu_item_hijas.push(itemadd);
        if (element.menu_item_hijas)
          this.anadir_items_hijas(itemadd, element.menu_item_hijas);
      });
    },
    eliminar() {
      if (this.menu.id)
        this.menu.eliminar = this.menu.eliminar.concat(this.elementsEliminados);
      this.elementsEliminados = [];
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    guardar() {
      this.menu.menuitems = this.elements;
      this.menu.estado = this.estado.id;
      if (this.menu.id) {
        this.menu.eliminar = this.menu.eliminar.concat(this.elementsEliminados);
        axios
          .put(`/menus/${this.menu.id}`, this.menu)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/menus", this.menu)
          .then((response) => {
            this.elements = [];
            this.success("Insertado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "menus" });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>