<template>
  <b-card no-body>
    <!--<b-card-text>{{selected}}</b-card-text>-->
    <b-row>
        <b-col>
          <b-overlay :show="loading">
      <b-form-group label="Paginas Recientes">
        <b-form-checkbox
          v-for="pagina in items"
          :key="pagina.id"
          v-model="elements"
          :value="pagina"
          name="flavour-3a"
        >
          {{ pagina.titulo }}
        </b-form-checkbox>
      </b-form-group>
      </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from '@axios';
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "tipo_entrada.nombre", label: "Tipo", sortable: false },
        { key: "published_at", label: "Fecha", sortable: false },
        { key: "estado", label: "Estado", sortable: false },
        { key: "titulo", label: "Titulo", sortable: false },
        { key: "user.name", label: "Autor", sortable: false },
        { key: "categoria.nombre", label: "Categoria", sortable: false },
        { key: "created_at", label: "Creado", sortable: false },
        "accion",
      ],
      selected: [],
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading: false,
      estado: [
        {
          1: "Publicado",
          2: "Borrador",
          3: "Papelera",
        },
        {
          1: "light-primary",
          2: "light-warning",
          3: "light-danger",
        },
      ],
      item: [],
    };
  },
  created() {
    this.loading = true
    this.$store.dispatch("aparienciaMenus/updateElementsEntradas", []);
    this.getEntradasRecientes()
  },
  computed: {
    elements: {
      get() {
        return this.$store.state.aparienciaMenus.entradas_elementos;
      },
      set(value) {
        this.$store.dispatch("aparienciaMenus/updateElementsEntradas", value);
      }
    }
  },
  methods: {
    getEntradasRecientes() {
      var variable = "?recientes=1";
      axios
        .get(`/menus/listar/entradas${variable}`)
        .then(res => {
           
          this.items = res.data.data;
         
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>
